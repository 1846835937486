import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgPipesModule } from 'ngx-pipes';
import { CrowdsourceHackathonRoutingModule } from './crowdsource-hackathon.routing.module';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { ProblemStatementsComponent } from './problem-statements/problem-statements.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProspectProvidersComponent } from './prospect-providers/prospect-providers.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { CreateProspectProviderComponent } from './create-prospect-provider/create-prospect-provider.component';
import { CreateStatementComponent } from './create-statement/create-statement.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProblemStatementDetailComponent } from './problem-statement-detail/problem-statement-detail.component';
import { ProblemStatementResponseDetailComponent } from './problem-statement-response-detail/problem-statement-response-detail.component';
import { QuillModule } from 'ngx-quill';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    IndexComponent,
    AcknowledgementComponent,
    ProblemStatementsComponent,
    ProspectProvidersComponent,
    CreateProspectProviderComponent,
    CreateStatementComponent,
    ProblemStatementDetailComponent,
    ProblemStatementResponseDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    NgxPermissionsModule.forChild(),
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgbModule,
    PipeModule,
    MatChipsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    NgPipesModule,
    CrowdsourceHackathonRoutingModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    QuillModule,
    MatStepperModule
  ],
  exports: [],
})
export class CrowdsourceHackathonModule { }
