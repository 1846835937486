<div class="row">
    <div class="col-sm-6 trn-trc">
        <h5>Prospect Providers </h5>
    </div>
    <div class="col-sm-6 trn-trc-1">
        <button mat-mini-fab color="primary" aria-label="add" (click)="navigateById('new')">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

<div class="row mt-4">
    <!-- <div class="col-sm-8">
        <div style="overflow-x: scroll">
            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
        </div>
    </div> -->
    <!-- <div class="col-sm-4 trn-tc-2">
        <input type="text" placeholder="Search ..." class="form-control">
    </div> -->
</div>
<div class="trn-trc">
    <span *ngFor="let i of alpha" style="text-transform: uppercase;" class="letter" [ngClass]="{'letterActive': i === searchbyLetter}" [ngClass.letterActive]="searchbyLetter == i" (click)="searchByLetter(i)">{{i}}</span>
</div>

<div class="tab-holder" *ngIf="serviceProviderList?.length > 0">
    <div class="flex-container-1">
        <div class="flex-item p-1" *ngFor="let provider of serviceProviderList;">
            <div class="trn-x" [ngStyle]="{'background': provider.color}">
                <div class="d-flex">
                    <!-- <span class="name-1" [ngStyle]="{'backgroundColor':getGMapRandomColor()}">{{getName(provider?.name)}}</span> -->
                        <div>
                            <img *ngIf="provider.url" src="{{provider.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                            <span class="name-1" *ngIf="!provider.url"   [ngStyle]="{'backgroundColor': provider.logoColor}">{{getName(provider.name)}}</span>
                        </div>
                        <h6 class="mb-0 mt-3 ml-2 trn-nm" style="width: 80%" matTooltip="{{provider.name}}"> {{provider.name}}
                        </h6>
                        <mat-icon class="fs-6" (click)="navigateById(provider.id)"> edit</mat-icon> 
                </div>
                <div class="d-flex mt-2 text-capitalize" style="font-size:12px">
                    <!-- <mat-icon> business</mat-icon>  -->
                   {{provider?.sector}} | {{provider?.category}} | {{provider?.area_expertise}}
                </div>
                
                <div class="st-col text-capitalize" >
                    <span style="background-color: green; font-size: 12px;">
                        {{ provider?.is_gdg == true ? 'Empanelled' :''}}
                    </span>
                     </div>
                    
                <div class="row" style="position:absolute;bottom:8px;font-size:12px">
                    <div class="col-sm-8 d-flex justify-content-start" style="font-size: 8px;">
                        <a *ngIf="provider.link" href="{{provider.link}}" target="_blank"><mat-icon style="color:#fff;font-size:20px">link</mat-icon></a>
                        <a href="mailto:{{provider?.email}}" ><mat-icon style="color:#fff;font-size:20px">mail</mat-icon></a>
                        <a href="tel:{{provider?.contact_no}}">	<mat-icon style="color:#fff;font-size:20px"> phone</mat-icon></a>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-end float-right">
                        <mat-icon *ngIf="provider.is_recomended">thumb_up_alt</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="serviceProviderList?.length == 0">
    <ng-container *ngTemplateOutlet="noResponse" ></ng-container>
</div>

<ng-template #noResponse>
    <div class="container">
    <div class="row">
        <!-- <div class="col-md-5 mx-auto mt-5">
            <img src="./assets/images/no-record.png" class="mt-3"
                height="100%" width="100%">
        </div> -->
        <div class="col-md-8 mx-auto mt-4">

            <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                {{staticText?.common.no_data_found}}
            </p>
        </div>
    </div>
</div>
</ng-template>




 

      
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
  
