import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { ProblemStatementsComponent } from './problem-statements/problem-statements.component';
import { ProspectProvidersComponent } from './prospect-providers/prospect-providers.component';
import { CreateProspectProviderComponent } from './create-prospect-provider/create-prospect-provider.component';
import { CreateStatementComponent } from './create-statement/create-statement.component';
import { ProblemStatementDetailComponent } from './problem-statement-detail/problem-statement-detail.component';
import { ProblemStatementResponseDetailComponent } from './problem-statement-response-detail/problem-statement-response-detail.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: "Challenge Workbench" },
    children: [
      {
        path:'',
        redirectTo:'challenge-statements'
      },
      {
        path: 'acknowledgement',
        data: { breadcrumb: 'Acknowledgement' },
        component: AcknowledgementComponent
      },
      {
        path: 'challenge-statements',
        data: { breadcrumb: 'Challenge Statements' },
        component: ProblemStatementsComponent
      },{
        path: 'prospectproviders',
        data: { breadcrumb: 'Prospect Providers' },
        component: ProspectProvidersComponent
      },{
        path: 'create-prospect-provider/:id',
        data: { breadcrumb: 'Create Prospect Providers' },
        component: CreateProspectProviderComponent
      },{
        path: 'create-statement/:id',
        data: { breadcrumb: 'Create Statement' },
        component: CreateStatementComponent
      },{
        path: 'problem-statement/:id',
        data: { breadcrumb: 'Challenge Statement Detail' },
        component: ProblemStatementDetailComponent
      },{
        path: 'problem-statement-response/:id',
        data: { breadcrumb: 'Challenge Statement Response' },
        component: ProblemStatementResponseDetailComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrowdsourceHackathonRoutingModule { }
