<div *ngIf="csDetails">
    <div class="status text-capitalize" [style.background-color]="csDetails?.status == 'draft' ? 'red' : ''">{{csDetails?.status}}</div>
    <div class="d-flex">
        <h6>{{csDetails?.title}}</h6> 
        <mat-icon aria-hidden="false" matTooltip="Edit Statement" class="cursor-pointer fs-4 ml-2"  *ngIf="csDetails?.status == 'draft'" (click)="editStatementURL()">
            edit_note
        </mat-icon>
    </div>
    <app-tag *ngIf="csDetails?.tag_ids.length>0" [tagsIds]="csDetails?.tag_ids" ></app-tag>
   <!-- <div>
     <p class="desc-style mt-2">
        {{csDetails?.description}}
    </p>
    <div *ngIf="csDetails.impact">
        <h6>Impact</h6>
        <p class="desc-style">{{csDetails?.impact}}</p>
    </div>
    <div *ngIf="csDetails.technologies">
        <h6>Technology stack/preferences</h6>
        <p class="desc-style">{{csDetails?.technologies}}</p>
    </div>
    <div *ngIf="csDetails.technologies">
        <h6>Last date of submission</h6>
        <div class="d-flex">
            <mat-icon>date_range</mat-icon>
            <p class="desc-style ml-1"> {{csDetails?.submission_date | date:'yyyy-MMM-dd'}}</p>
        </div>
    </div>
    <div *ngIf="csDetails.poc_expectations">
        <h6>POC expectations</h6>
        <p class="desc-style">{{csDetails?.poc_expectations}}</p>
    </div>
    
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false" style="border-radius: 20px;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Attachments ({{csDetails?.problem_statement_files.length}})
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="padding: 20px; overflow-x: scroll">
            <div class="d-flex flex-row flex-nowrap overflow-auto" *ngIf="csDetails?.problem_statement_files.length > 0" style="padding: 10px 0">
                <div class="card card-block mx-2" style="width: 150px; height: 150px; display: flex; 
                    align-items: center; justify-content: center; border-radius: 15px;padding:0 4px;" 
                    *ngFor="let x of csDetails?.problem_statement_files; let i = index" (click)="downloadFile(x)">
                    <mat-icon style="position: absolute;top:0;right:4px;">cloud_download</mat-icon>
                        <img *ngIf="x?.file_type == 'doc' || x?.file_type == 'docx'"
                            src="./assets/images/word.svg" height="40" style="width: 30px;">
                        <img *ngIf="x?.file_type == 'pdf'" src="./assets/images/pdf.svg"
                            height="40" style="width: 30px;">
                        <img *ngIf="x?.file_type == 'xls' || x?.file_type == 'xlsx'"
                            src="./assets/images/excel.svg" height="40" style="width: 30px;">
                        <img *ngIf="x?.file_type == 'csv'" src="./assets/images/csv.png"
                            height="40" style="width: 30px;">
                        <mat-icon
                            *ngIf="x?.file_type !== 'pdf' && x?.file_type !== 'doc' && x?.file_type !== 'docx' && x?.file_type !== 'xls' && x?.file_type !== 'xlsx' && x?.file_type !== 'csv'"
                            mat-list-icon class="material-icons my-auto fs-1 text-danger" style="width: 30px;">
                            description</mat-icon>  
                        <p class="m-0">{{x?.file_name}} </p>
                </div>
            </div>  
            <div *ngIf="csDetails?.problem_statement_files.length == 0">
                No files attached.
            </div>        
        </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="d-block mt-2">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false" style="border-radius: 20px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    OKR Selected ({{csDetails?.problem_statement_okr.length}})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="padding: 20px; overflow-x: scroll">
                <div class="d-flex flex-row flex-nowrap overflow-auto" style="padding: 2px 0">
                    <div *ngIf="csDetails?.problem_statement_okr.length > 0">
                        <div class="okr-box-style" *ngFor="let okr of csDetails?.problem_statement_okr" [ngStyle]="{'background': okr.color}" >
                            {{okr?.title}}

                            <b style="color:#9e9393;font-size: 12px;display:block;">Comment</b>
                            <span style="font-size:12px">{{okr?.comment}}</span>
                        </div>
                    </div>
                    <div *ngIf="csDetails?.problem_statement_okr.length == 0">
                        No OKR selected.
                    </div>
                </div>          
            </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="d-block mt-2">
        <mat-accordion *ngIf="csDetails?.serviceprovider">
            <mat-expansion-panel (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false" style="border-radius: 20px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Prospect Provider  ({{csDetails?.serviceprovider.length}})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-container-1">
                <div class="flex-item p-1" *ngFor="let provider of csDetails?.serviceprovider;">
                    <div class="trn-x" [ngStyle]="{'background': provider.color}">
                        <div class="d-flex">
                            <img src="assets/images/pages/images.jpg" style="width:40px;height:40px;border-radius: 50%;"/>
                            <h6 class="mb-0 mt-3 ml-2 trn-nm" style="width: 80%" matTooltip="{{provider.name}}"> {{provider.name}}
                            </h6>
                        </div><br>
                        <div class="d-flex justify-content-start" matTooltip="Sector" style="font-size:12px">
                            <mat-icon> business</mat-icon> 
                            <span class="ml-1 mt-1 fw-500">{{provider.sector}}</span>
                        </div>
                        <div class="row" style="position:absolute;bottom:8px;font-size:12px">
                            <div class="col-sm-8 d-flex justify-content-start" style="font-size: 8px;">
                                <a *ngIf="provider.link" href="{{provider.link}}" target="_blank"><mat-icon style="color:#fff;font-size:20px">link</mat-icon></a>
                                <a *ngIf="provider.email" href="mailto:{{provider?.email}}" ><mat-icon style="color:#fff;font-size:20px">mail</mat-icon></a>
                                <a *ngIf="provider.contact_no" href="tel:{{provider?.contact_no}}">	<mat-icon style="color:#fff;font-size:20px"> phone</mat-icon></a>
                            </div>
                            <div class="col-sm-4 d-flex justify-content-end float-right">
                                <mat-icon *ngIf="provider.is_recomended">thumb_up_alt</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div> -->
    <hr>
    <h6 class="mt-3">Responses</h6>

    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Recently Submitted ({{statementResponse?.submitted?.length}})">
            <div class="response-card-style" *ngFor="let res of statementResponse?.submitted"  (click)="navigateToById('challenge-workbench/problem-statement-response/',res.id)">
                <div class="mr-1">
                    <img *ngIf="res?.vendor?.url" src="{{res?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                    <span class="name-1" *ngIf="!res?.vendor?.url"   [ngStyle]="{'backgroundColor': res?.vendor?.logoColor}">{{getName(res?.vendor?.name)}}</span>
                </div>
                <div style="color: #AB8114">
                    Provider: <b>{{res?.vendor?.name}}</b> | Sector: <b>{{res?.vendor?.sector}}</b> | Type: <b>{{res?.vendor?.type}}</b> <br>
                    <span style="display: block; font-size: 11px;  color: #817777;">Updated <b>{{updateTimings(res?.last_update)}}</b></span>
                </div>
            </div>
            <div *ngIf="statementResponse?.submitted?.length == 0">
                <ng-container *ngTemplateOutlet="noResponse" ></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="Shortlisted ({{statementResponse?.shortlist?.length}})">
            <div class="response-card-style" *ngFor="let res of statementResponse?.shortlist"  (click)="navigateToById('challenge-workbench/problem-statement-response/',res.id)">
                <div class="mr-1">
                    <img *ngIf="res?.vendor?.url" src="{{res?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                    <span class="name-1" *ngIf="!res?.vendor?.url"   [ngStyle]="{'backgroundColor': res?.vendor?.logoColor}">{{getName(res?.vendor?.name)}}</span>
                </div>
                <div style="color: #AB8114">
                    Provider: <b>{{res?.vendor?.name}}</b> | Sector: <b>{{res?.vendor?.sector}}</b> | Type: <b>{{res?.vendor?.type}}</b> <br>
                    <span style="display: block; font-size: 11px;  color: #817777;" *ngIf="res?.last_update">Updated <b>{{updateTimings(res?.last_update)}}</b></span>
                </div>
            </div>
            <div *ngIf="statementResponse?.shortlist?.length == 0">
                <ng-container *ngTemplateOutlet="noResponse" ></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="Rejected ({{statementResponse?.reject?.length}})">
            <div class="response-card-style" *ngFor="let res of statementResponse?.reject"  (click)="navigateToById('challenge-workbench/problem-statement-response/',res.id)">
                <div class="mr-1">
                    <img *ngIf="res?.vendor?.url" src="{{res?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                    <span class="name-1" *ngIf="!res?.vendor?.url"   [ngStyle]="{'backgroundColor': res?.vendor?.logoColor}">{{getName(res?.vendor?.name)}}</span>
                </div>
                <div style="color: #AB8114">
                    Provider: <b>{{res?.vendor?.name}}</b> | Sector: <b>{{res?.vendor?.sector}}</b> | Type: <b>{{res?.vendor?.type}}</b> <br>
                    <span style="display: block; font-size: 11px;  color: #817777;">Updated <b>{{updateTimings(res?.last_update)}}</b></span>
                </div>
            </div>
            <div *ngIf="statementResponse?.reject?.length == 0">
                <ng-container *ngTemplateOutlet="noResponse" ></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="Accelerator  ({{statementResponse?.accelarator?.length}})">
            <div class="response-card-style" *ngFor="let res of statementResponse?.accelarator"  (click)="navigateToById('challenge-workbench/problem-statement-response/',res.id)">
                <div class="mr-1">
                    <img *ngIf="res?.vendor?.url" src="{{res?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                    <span class="name-1" *ngIf="!res?.vendor?.url"   [ngStyle]="{'backgroundColor': res?.vendor?.logoColor}">{{getName(res?.vendor?.name)}}</span>
                </div>
                <div style="color: #AB8114">
                    Provider: <b>{{res?.vendor?.name}}</b> | Sector: <b>{{res?.vendor?.sector}}</b> | Type: <b>{{res?.vendor?.type}}</b> <br>
                    <span style="display: block; font-size: 11px;  color: #817777;">Updated <b>{{updateTimings(res?.last_update)}}</b></span>
                </div>
            </div>
            <div *ngIf="statementResponse?.reject?.length == 0">
                <ng-container *ngTemplateOutlet="noResponse" ></ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>


<ng-template #noResponse>
    <div class="container">
    <div class="row">
        <!-- <div class="col-md-5 mx-auto mt-5">
            <img src="./assets/images/no-record.png" class="mt-3"
                height="100%" width="100%">
        </div> -->
        <div class="col-md-8 mx-auto">

            <p class="text-center text-muted mt-2 font-weight-bold mb-1 mt-4">
                No response found
            </p>
        </div>
    </div>
</div>
</ng-template>