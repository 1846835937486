import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
@Component({
    selector: 'app-problem-statement-response-detail',
    templateUrl: './problem-statement-response-detail.component.html',
    styleUrls: ['./problem-statement-response-detail.component.scss']
})
export class ProblemStatementResponseDetailComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    status: string = 'Draft';
    statementResponse:any;
    csResponseId:any;
    responseComment:any = [];
    refreshComment:boolean = false;
    jointStatus:any = ['shortlist','reject','in accelerator']
    constructor(private router: Router,
        private route: ActivatedRoute,
        private cw: ChallengeWorkbenchService,
        private spinner : NgxSpinnerService,
        private toastrService : ToastrService,
        private breadCrumService: BreadcrumbService,
    ) { }

    ngOnInit(): void {
        
        this.route.paramMap.subscribe((res: any) => {
            this.csResponseId = atob(res.get('id'));
            this.getChallengeStatementResponseById();
          });
    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    statusChange(status:any){
        this.spinner.show();
        const formData = new FormData();
       formData.append('status',status);
        this.cw.updateServiceProviderResponse(formData,this.csResponseId).subscribe(async (res:any)=>{
            if(res){
              this.spinner.hide();
              this.toastrService.success('Response '+status+' successfully.')
              this.statementResponse.status = status;
            }
          });
    }

    getChallengeStatementResponseById(){
        this.spinner.show();
        this.cw.getChallengeStatementResponseById(this.csResponseId).subscribe((res:any)=>{
            this.statementResponse = res;
            this.statementResponse?.challengestatement?.vendor.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.statementResponse?.challengestatement?.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.cw.setData(this.statementResponse?.challengestatement);
            this.spinner.hide();
            let last = [
                {label:'CoSInE' , url:'/challenge-workbench/challenge-statements'},
                {label:'Challenge Workbench' , url:'/challenge-workbench/challenge-statements'},
                {label:this.statementResponse?.challengestatement?.title , url:'/challenge-workbench/problem-statement/'+btoa(this.statementResponse?.challengestatement?.id) },
                {label:this.statementResponse?.vendor?.name,url:''}
              ]
               this.breadCrumService.setBreadcrumb(last);
            this.getResponseComment();
        });
    }

    
    ngOnDestroy() {
        this.cw.setData('');
    }

     getResponseComment(){
        this.cw.getResponseComments(this.csResponseId).subscribe((res:any)=>{
            this.responseComment = res;
        })
    }
   
    receiveDataFromComment(event:any){
        this.spinner.show();
        this.refreshComment = false;
        const formData = new FormData();
        if(event?.text || event?.document){
            if(event.text)
            formData.append('text',event.text);
            if(event.document){
            formData.append('document',event.document);
            }
            formData.append('response_id',this.csResponseId);
            formData.append('parent','');
            this.cw.postComments(formData).subscribe((res: any) => {
                this.responseComment.push(res);
                this.refreshComment = true;
                this.spinner.hide();
            })
        }   
    }
    
    openLink(x:any){
        if(x){
            window.open(x, '_blank');
        }
    }

    downloadCommentFile(event:any){
        this.spinner.show();
        this.cw.downloadCommentFile(event.id).subscribe((res: any) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute('download', event.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success('File downloaded successfully');
        this.spinner.hide();
        }, (error: any) => {
        this.spinner.hide();
        throw error;
        })
    }

    moveToAccelerator(){
        this.spinner.show();
        let data={source_id:this.csResponseId,source_type:'challenge',status:'under_commercials'}
         this.cw.moveToAccelerator(data).subscribe(async (res:any)=>{
            if(res){
            this.spinner.hide();
            this.toastrService.success('Moved to Delevery Accelerator');
            this.router.navigate(['delivery-accelerator/dashboard']);
            }
        });
    }
}
