import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup,FormArray,Validators, FormControl } from '@angular/forms';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { TagsService } from 'src/app/shared/services/tag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ProgramService } from 'src/app/shared/services/program.service';
import { MessageService } from 'src/app/shared/services/message.service';


@Component({
  selector: 'app-create-statement',
  templateUrl: './create-statement.component.html',
  providers: [DatePipe],
  styleUrls: ['./create-statement.component.scss']
})
export class CreateStatementComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper|any;
  okr: any = [];
  panelOpenState = false;
  panelOpenState1 = false;
  step: any = '1';
  
  alpha: any;
  displayedColumns: string[] = ['select', 'org', 'accelerator', 'industry', 'sector' ,'category', 'tags'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  serviceProviderList:any = [];
  stepStates:any=[];
  selectedServiceProvider:any=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  challengeStatementForm: FormGroup | any;
  createStatementTags:any;
  csId:any;
  problemStatementDetails:any;
  searchbyLetter:any = '';
  options2: any;
  filteredOptions2: any;
  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  programOptions: any;
  programFilteredOptions: any;
  filterOptionType: Observable<any[]> | any;
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;
  subPortfilteredOptions: any;
  subPortFolioChild:any;
  ownerDetails:any;
  linkURL: any;
  loading:any = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cw: ChallengeWorkbenchService,
    private toastrService : ToastrService,
    private spinner : NgxSpinnerService,
    private datepipe: DatePipe,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private breadCrumService: BreadcrumbService,
    private ps: ProgramService,
    private messageService: MessageService
    ) {
    this.challengeStatementForm = this.fb.group({
      title:['',Validators.required],
      description:['',Validators.required],
      impact:['',Validators.required],
      technologies:['',Validators.required],
      submission_date:['',Validators.required],
      poc_expectations:['',Validators.required],
      status:[''],
      files: this.fb.array([]),
      okrs: this.fb.array([]),
      portfolio:['',Validators.required],
      subPortfolio:['',Validators.required],
      program:['',Validators.required],
      owners:['',Validators.required],
      owner_id:[''],
      links: this.fb.array([]),
    });
   }
  

  ngOnInit(): void {
    this.messageService.setGnews('Challenge Statement creation')
    this.alpha = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "A-Z"];
    this.route.paramMap.subscribe((res: any) => {
      this.csId = atob(res.get('id'));
      this.getOKRObjective();
      this.getAllServiceProvider();
      this.getList();
    
    });
   
  }

  addLink(){
    this.challengeStatementForm.value.links.push(this.linkURL);
    this.linkURL = "";
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 25%, 40%, 90%)';
  } 

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  next(){
    this.challengeStatementForm.markAllAsTouched();
    if(this.challengeStatementForm.invalid){
      return;
    }
    this.stepper.next();
    this.step = '2';
  }

  previous(){
    this.stepper.previous();
  }

  selectedStepper(e: StepperSelectionEvent) {
    this.stepper.steps.forEach((step:any,index:number) => {
      if( index == e.selectedIndex){
        this.stepStates[index] = 'edit';
      }else if(!step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'number';
      }else if(step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'done';
      }      
    });
  }
//==========================File Control===========================//
  createFileControl(file:any): FormControl {
    return this.fb.control(file); // You can set a default file if needed
  }

  addFile(file:any) {
    this.fileControls.push(this.createFileControl(file));
  }

  get fileControls() {
    return (this.challengeStatementForm.get('files') as FormArray);
  }
  removeFile(index: number) {
    this.fileControls.removeAt(index);
  }
//============================OKR Control============================//
  createAddOKRControl(okr:any): FormControl {
    return this.fb.control(okr); // You can set a default file if needed
  }
  addOKR(event:any,okr:any){
    okr.comment = '';
    if(event.checked){
      this.okrControls.push(this.createAddOKRControl(okr));
    }else{
     let index = this.okrControls.value.findIndex((x:any) => x.uuid == okr.uuid);
     if(index){
      this.okrControls.removeAt(index);
     }
    }
  }

  get okrControls() {
    return (this.challengeStatementForm.get('okrs') as FormArray);
  }
  removeOKR(index: number) {
    this.okrControls.removeAt(index);
  }

  async getOKRObjective(){
    await this.cw.getOkrList().subscribe((res:any)=>{
      res.forEach((element:any) => {
        element.color = this.getGMapRandomColor();
        element.selected=false;
        this.okr.push(element);
      }); 
    })
  }
  searchByLetter(letter:any){
    this.searchbyLetter = letter;
    if(letter == "A-Z"){
      this.searchbyLetter = "";
    }
    this.getAllServiceProvider();
  }

   getAllServiceProvider(){
    this.spinner.show();
    this.serviceProviderList = [];
     this.cw.getAllVendorList(this.searchbyLetter).subscribe((res:any)=>{
      res.forEach((element:any) => {
        element.color = this.getGMapRandomColor();
        element.logoColor = this.getGMapRandomColor();
        element.selected = false;
        this.serviceProviderList.push(element)
       });
      if(this.csId != 'new'){
        this.getChallengeStatementById();
      }
      this.spinner.hide();
    })
   }

  onFileSelected(event:any){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
     this.addFile(file);
    }
  }
  getType(x:any){
    const ext = x.substring(x.lastIndexOf('.') + 1);
    return ext?.toLowerCase()
  }

  submitData(type:any){
    if(type == 'published' && this.selectedServiceProvider.length == 0){
      this.toastrService.error('please select atleast one provider');
      return
    }
    this.challengeStatementForm.markAllAsTouched();
    if(this.challengeStatementForm.invalid){
      this.stepper.previous();
      return;
    }
   
    this.spinner.show();
    
    let OKRList:any = [];
    this.challengeStatementForm.value.okrs.forEach((ele:any)=>{
      OKRList.push({okr:ele.uuid,comment:ele.comment})
    })
    this.challengeStatementForm.value.submission_date = this.datepipe.transform(this.challengeStatementForm.value.submission_date,"yyyy-MM-dd");
   let dataFiles:any=[]
    const formData = new FormData();
    formData.append('title',this.challengeStatementForm.value.title);
    formData.append('description',this.challengeStatementForm.value.description);
    formData.append('impact',this.challengeStatementForm.value.impact);
    formData.append('technologies',this.challengeStatementForm.value.technologies);
    formData.append('submission_date',this.challengeStatementForm.value.submission_date);
    formData.append('poc_expectations',this.challengeStatementForm.value.poc_expectations);
    formData.append('status',type);
    this.challengeStatementForm.get('files').value.forEach((file:any) => { formData.append('files', file)});
    formData.append('okrs',JSON.stringify(OKRList));
    formData.append('vendor',JSON.stringify(this.selectedServiceProvider));
    formData.append('portfolio_id',this.challengeStatementForm.value.subPortfolio.id);
    formData.append('program_id',this.challengeStatementForm.value.program.id);
    formData.append('owner_id',this.challengeStatementForm.value.owner_id);
    formData.append('links', JSON.stringify(this.challengeStatementForm.value.links));

    console.log(this.challengeStatementForm);
    if(this.csId == 'new'){
      this.createNewChallengeStatement(formData);
    }else{
      this.updateChallengeStatement(formData);
    }
  }

  createNewChallengeStatement(formData:any){
    this.cw.createChallengeStatement(formData).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Challenge Statement Created Successfully.")
        this.addTags(res.id);
        this.router.navigate([`challenge-workbench/challenge-statements`]);
      }
    });
  }
  updateChallengeStatement(formData:any){
    this.cw.updateChallengeStatement(formData,this.csId).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Challenge Statement updated Successfully.")
        this.addTags(res.id);
        this.router.navigate([`challenge-workbench/challenge-statements`]);
      }
    });
  }
  async addTags(id:any){
      if(this.createStatementTags?.length > 0){
        await this.createStatementTags.forEach(async (element: any) => {
          let pst = {
            entity_id: id,
            entity_type: "workbench",
            tag_id: element.id
          }
          await this.tagService.magTag(pst).subscribe((res:any) => {});
        });
      }
  }
  
  getChildTags(data: any){
    this.createStatementTags = data;
  }

  selectServiceProvider(event:any,id:any){
    if(event.checked){
      this.selectedServiceProvider.push(id);
    }else{
      this.selectedServiceProvider = this.selectedServiceProvider.filter((item:any) => item !== id);
    }
  }

  getChallengeStatementById(){
    this.spinner.show();
    this.cw.getChallengeStatementById(this.csId).subscribe((res:any)=>{
        this.spinner.hide();
        res.vendor.forEach((element:any) => {
            element.color = this.getGMapRandomColor();
        });
        res.problem_statement_okr.forEach((element:any) => {
            element.color = this.getGMapRandomColor();
        });
        this.problemStatementDetails = res;
        this.createStatementTags = res.tag_ids;
        let last = [
          {label:'CoSInE' , url:'/challenge-workbench/challenge-statements'},
          {label:'Challenge Workbench' , url:'/challenge-workbench/challenge-statements'},
          {label:res?.title , url:'' },
        ]
        this.breadCrumService.setBreadcrumb(last);
        this.patchResponse(res)
    })
  }

  patchResponse(res:any){
    this.challengeStatementForm.setValue({
      title: res?.title, 
      description: res?.description,
      impact: res?.impact,
      technologies: res?.technologies,
      submission_date: res?.submission_date,
      poc_expectations: res?.poc_expectations,
      status: res?.status,
      files:[],
      okrs:[],
      links:[],
      portfolio: res?.portfolio?.parent_portfolio ? res?.portfolio?.parent_portfolio : '',
      subPortfolio: res?.portfolio ? res?.portfolio : '',
      program: res?.program ? res?.program : '',
      owners: res?.owner ? res?.owner?.first_name +  res?.owner?.last_name : '',
      owner_id: res?.owner ? res?.owner?.id : ''
    });

    this.okr.forEach((element:any) => {
      res?.problem_statement_okr.forEach((kr:any) => {
        if(kr.okr == element.uuid){
          element.selected = true;
          element.comment = kr.comment;
          this.okrControls.push(this.createAddOKRControl(element));
        }
      });
    });

    this.serviceProviderList.forEach((element:any) => {
      res.vendor.forEach((sp:any) => {
        if(element.id == sp.id){
          this.selectedServiceProvider.push(element.id);
          element.selected = true;
        }
      });
    });
  
    res?.links.forEach((link:any) => {
      this.linksControls.push(this.createLinksControl(link));
    });
  }

  DeleteFile(fileId:any){
    this.spinner.show();
    this.cw.deleteChallengeStatementFile(fileId).subscribe((res:any)=>{
        this.spinner.hide();
        this.problemStatementDetails.problem_statement_files = this.problemStatementDetails?.problem_statement_files?.filter((item:any) => item.id != fileId);
    });
  }
  getName(name: any) {
    return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
  }


  //==========================================================================================

  async getList(type?: any) {
    //this.spinner.show();
   
    combineLatest([

      this.ps.getOpportunityType().pipe(tap((res:any) => {
        this.options2 = res.opportunity_type;
        this.filteredOptions2 = this.options2;      
      })),
   
      this.ps.getOpportunityHierarchy().pipe(tap((res:any) => {
        this.options1 = res.records;
        this.filteredOptions1 = this.options1;
            
      })),
    
      this.ps.getProgramData().pipe(tap((res:any) => {
        this.programOptions = res;
          this.programFilteredOptions = this.programOptions;
        
            
      })
      )
   
   ]).subscribe((resultArray) => {
    this.initAutoComplete()

   })
  }

  initAutoComplete(): any {
    
  this.filterOptionPortfolio = this.challengeStatementForm.get('portfolio')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
    );
    this.filterOptionProgram = this.challengeStatementForm.get('program')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.programFilter(name) : this.programFilteredOptions?.slice())
    );   
  // this.valuePatch(this.patchResponse);
  }

  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programFilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.challengeStatementForm.patchValue({
      subPortfolio: '',
      owners:'',
      owner_id:''
    })
    
    
    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.challengeStatementForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
    );
  }  

  onSelectionChangeSubportPortfolio(e: any) {    
    const subportFolioId = e.source.value.id;
    if (!subportFolioId) {
      return;
    }  
    let value = e.source.value;  
    this.subPortFolioChild =[];
    this.ownerDetails = value.owner_details;
      this.challengeStatementForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username,
        owner_id:value.owner_details?.[0]?.id
      })
  }

  get linksControls() {
    return (this.challengeStatementForm.get('links') as FormArray);
  }

  createLinksControl(link:any): FormControl {
    return this.fb.control(link);
  }

  displayFnName(data: any) {
    return data && data.name ? data?.name : data?.full_name;
  }

  removeLink(i: any){
    this.challengeStatementForm.value.links.splice(i, 1)
  }

  openLink(x:any){
    if(x){
        window.open(x, '_blank');
    }
}
}