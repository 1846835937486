import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray,Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TagsService } from 'src/app/shared/services/tag.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-prospect-provider',
  templateUrl: './create-prospect-provider.component.html',
  styleUrls: ['./create-prospect-provider.component.scss']
})
export class CreateProspectProviderComponent implements OnInit {
    statements: any;
    manageProviderTags:any =[];
    serviceProviderForm: FormGroup | any;
    allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    imageUrl: string | ArrayBuffer | null | any = null;
    spId:any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastrService : ToastrService,
    private cw : ChallengeWorkbenchService,
    private spinner : NgxSpinnerService,
    private tagService: TagsService,
    private route: ActivatedRoute,
    
  ) {
    this.serviceProviderForm = this.fb.group({
      name:['',Validators.required],
      type:['',Validators.required],
      year:['',Validators.required],
      year_of_incorp:['',Validators.required],
      location:['',Validators.required],
      nearest_hub:['',Validators.required],
      sector:['',Validators.required],
      profile: ['',Validators.required],
      founder_name: ['',Validators.required],
      founder_email:['',Validators.required],
      contact_no:['',Validators.required],
      email:['',Validators.required],
      category:['',Validators.required],
      area_expertise:['',Validators.required],
      link:['',Validators.required],
      is_gdg:['',Validators.required],
      logo:[''],
      contact_number: ['',Validators.required],
      contact_email: ['',Validators.required],
    });
   }
  

  ngOnInit(): void {
    this.route.paramMap.subscribe((res: any) => {
      this.spId = atob(res.get('id'));
      if(this.spId != 'new'){
        this.getProspectProviderDetails();
      }
    });
   }
  
  getChildTags(data: any){
    this.manageProviderTags = data;
  }
  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  onFileSelected(event:any){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if(!this.allowedImageTypes.includes(file.type)){
        this.toastrService.error('Invalid file type. Please select an image file.');
        return;
      }
      if (file.size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
      this.loadImageAsBase64(file);
      this.serviceProviderForm.get('logo').setValue(file);
    }
  }
  loadImageAsBase64(file: File): void {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imageUrl = e.target?.result;
    };

    reader.readAsDataURL(file);
  }

  submitServiceProvider(){
    this.serviceProviderForm.markAllAsTouched();
    if(this.serviceProviderForm.Invalid){
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    var data = this.serviceProviderForm.value;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if(key == 'logo' && !data[key]){
          continue;
        }else{
          formData.append(key, data[key]);
        }
      }
    }
    if(this.spId == 'new'){
      this.createNewServiceProvider(formData);
    }else{
      this.updatedServiceProvider(formData);
    }
  }

  createNewServiceProvider(formData:any){
    this.cw.createServiceProvider(formData).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Prospect Provider Created Successfully.")
        this.addTag(res.id);
        this.router.navigate([`challenge-workbench/prospectproviders`]);
      }
    })
  }

  updatedServiceProvider(formData:any){
    this.cw.updateServiceProvider(formData,this.spId).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Prospect Provider Updated Successfully.")
        this.addTag(res.id);
        this.router.navigate([`challenge-workbench/prospectproviders`]);
      }
    })
  }

  async addTag(id:any){
    if(this.manageProviderTags?.length > 0){
      await this.manageProviderTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "serviceprovider",
          tag_id: element.id
        }
        await this.tagService.magTag(pst).subscribe((res:any) => {});
      });
    }
  }

  getProspectProviderDetails(){
    this.spinner.hide();
    this.cw.getServiceProviderById(this.spId).subscribe((res:any)=>{
      this.patchResponse(res)
    })
  }

  patchResponse(res:any){
    this.serviceProviderForm.setValue({
      name: res?.name, 
      type: res?.type,
      year: res?.year,
      year_of_incorp: res?.year_of_incorp,
      location: res?.location,
      nearest_hub: res?.nearest_hub,
      sector: res?.sector,
      profile: res?.profile,
      founder_name: res?.founder_name,
      founder_email: res?.founder_email,
      contact_no: res?.contact_no,
      email: res?.email,
      category: res?.category,
      area_expertise: res?.area_expertise,
      link: res?.link,
      is_gdg: res?.is_gdg,
      logo: '',
      contact_number: res?.contact_number,
      contact_email: res?.contact_email
    });
  }

}
