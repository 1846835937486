<div>
    <div class="status text-capitalize" [ngStyle]="{'background': statementResponse?.status == 'draft' ? 'grey' : statementResponse?.status == 'submitted' ? 'green' : statementResponse?.status == 'shortlist' ? 'rgb(92, 141, 19)' : 'rgb(255, 85, 47)' }">{{statementResponse?.status}}</div>
    <h6>{{statementResponse?.challengestatement?.title}}</h6>
    <hr>
    <div class="row">
        <div class="col-sm-10" style="display: flex; align-items: center">
            <h5 class="m-0">
                Provider: <b>{{statementResponse?.vendor?.name}}</b> | 
                Sector: <b>{{statementResponse?.vendor?.sector}}</b> | 
                Type: <b>{{statementResponse?.vendor?.type}}</b></h5>
        </div>
        <div class="col-sm-2" style="display: flex; justify-content: end; align-items: center" >
            <button mat-button color="shortlist" *ngIf="!jointStatus.includes(statementResponse?.status)" class="mr-1" (click)="statusChange('shortlist')"> Shortlist </button>
            <button mat-button color="reject" *ngIf="!jointStatus.includes(statementResponse?.status)" class="ml-1" (click)="statusChange('reject')"> Reject </button>
            <button mat-button color="move" aria-label="add" *ngIf="statementResponse?.status == 'shortlist' " (click)="moveToAccelerator()">
                Move to Delivery <br> Accelerator<br><mat-icon>arrow_right_alt</mat-icon>
            </button>
        </div>
    </div><br>
    <app-statement-response-details [resDetails]="statementResponse"></app-statement-response-details>
    <hr>
    <div class="p-3">
        <app-comments (dataEvent)="receiveDataFromComment($event)" (downloadFileEvent)="downloadCommentFile($event)" [comments]="responseComment" [refresh]="refreshComment"></app-comments>
    </div>
</div>