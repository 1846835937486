<div class="row">
  <div class="col-sm-6 trn-trc">
    <h5>Prospect Provider Creation</h5>
  </div>
</div>
<form [formGroup]="serviceProviderForm">
  <div class="row mt-3">
    <div class="col-sm-8">
      <mat-form-field appearance="outline" class="w-100 px-1">
        <mat-label>Organization Name / Startup Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Accelerator Type</mat-label>
        <input matInput formControlName="type" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Year Found</mat-label>
        <input matInput formControlName="year" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Year of Incorp.</mat-label>
        <input matInput formControlName="year_of_incorp" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Current Location / HQ</mat-label>
        <input matInput formControlName="location" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Nearest Hub</mat-label>
        <input matInput formControlName="nearest_hub" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Sector</mat-label>
        <input matInput formControlName="sector" />
      </mat-form-field>
    </div>
    <div class="col-sm-4 text-center">
      <div class="image-hold" (click)="fileInput.click()" *ngIf="!imageUrl">
        <mat-icon class="upload-icon">upload</mat-icon>
      </div>
      <img
        *ngIf="imageUrl"
        class="image-hold"
        style="height: 200px; width: 200px"
        [src]="imageUrl"
        alt="Uploaded Image"
      />
      <button
        *ngIf="imageUrl"
        (click)="fileInput.click()"
        mat-button
        class="bg-primary text-white mt-2"
        mat-button
        mat-flat-button
      >
        Change Logo
      </button>
      <input
        hidden
        #fileInput
        type="file"
        id="file"
        (change)="onFileSelected($event)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field appearance="outline" class="w-100 px-1">
        <mat-label>Company Profile</mat-label>
        <textarea matInput formControlName="profile"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Founder Name</mat-label>
        <input matInput formControlName="founder_name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Founder Email</mat-label>
        <input matInput formControlName="founder_email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Founder Contact No.</mat-label>
        <input matInput formControlName="contact_no" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Company Email</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Category</mat-label>
        <input matInput formControlName="category" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Area of Expertise</mat-label>
        <input matInput formControlName="area_expertise" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Website Link</mat-label>
        <input matInput formControlName="link" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Client Partner Email</mat-label>
        <input matInput formControlName="contact_email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Client Partner Mobile</mat-label>
        <input matInput formControlName="contact_number" />
      </mat-form-field>
      <mat-checkbox formControlName="is_gdg" class="ml-4">Is GDP?</mat-checkbox>
      <app-tag
        [noIds]="true"
        [tagsIds]=""
        (childTags)="getChildTags($event)"
      ></app-tag>
    </div>
  </div>
</form>
<div class="trn-trc-1 mt-4">
  <button
    mat-button
    color="success"
    (click)="submitServiceProvider()"
    aria-label="add"
  >
    Submit
  </button>
</div>
