<div class="row">
  <div class="col-sm-6 trn-trc">
    <h5>Challenge Statements</h5>
  </div>
  <div class="col-sm-6 trn-trc-1" *ngIf="!user?.is_vendor">
    <button
      mat-flat-button
      color="primary"
      aria-label="add"
      (click)="navigateTo('vendor-master')"
    >
      Manage Vendors
    </button>
    <button
      mat-flat-button
      color="primary"
      aria-label="add"
      class="ml-2"
      (click)="navigateById('challenge-workbench/create-statement/', 'new')"
    >
      <mat-icon>add</mat-icon> Create Challenge
    </button>
  </div>
</div>
<!-- <div class="row mt-2">
    <div class="col-sm-8 mb-0">
        <div>
            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
        </div>
    </div>
    <div class="col-sm-4 trn-tc-2">
        <input type="text" placeholder="Search ..." class="form-control">
    </div>
</div> -->
<app-loading *ngIf="!dataLoaded"></app-loading>
<div *ngIf="dataLoaded">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Active ({{ statements.length ? statements.length : 0 }})">
      <div style="overflow-x: hidden">
        <div class="tab-holder mt-3" *ngIf="statements?.length > 0">
          <div class="flex-container-1">
            <div
              class="flex-item p-1"
              *ngFor="let x of statements; let i = index"
            >
              <div
                class="trn-x"
                [ngStyle]="{ background: x.color }"
                (click)="
                  navigateById('challenge-workbench/create-statement/', x.id)
                "
              >
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">
                    {{ x.title }}
                  </h6>
                  <div class="d-flex">
                    <span
                      class="tag-icon mr-1"
                      *ngIf="x?.tag_ids?.length > 0"
                      (click)="$event.stopPropagation()"
                      [matMenuTriggerFor]="menu"
                    >
                      <i class="far fa-tag" style="font-size: 10px"></i
                    ></span>
                    <span
                      mat-icon-button
                      color="warn"
                      (click)="
                        deleteChallenge(x.id, i); $event.stopPropagation()
                      "
                    >
                      <mat-icon
                        style="
                          font-size: 15px;
                          color: red;
                          height: 0;
                          width: 10px;
                        "
                        >delete</mat-icon
                      >
                    </span>
                  </div>
                </div>
                <mat-menu #menu="matMenu" class="my-class">
                  <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                </mat-menu>
                <!-- <div class="st-col text-capitalize" [ngStyle]="{'background': x.status == 'draft' ? '#d33838' : 'green'}">
                                    {{x.status}}</div> -->
                <p class="desc">{{ x.description }}</p>
                <div *ngIf="x.latest_date" class="lates-update-style">
                  <i>Updated {{ getLatestUpdated(x.latest_date) }}</i>
                </div>

                <div class="row rcp" *ngIf="x.status != 'draft'">
                  <div class="col-sm-6 d-flex justify-content-start">
                    <mat-icon class="fs-6"> contact_mail</mat-icon>
                    <h5 class="fs-6">
                      {{ x?.vendor?.length ? x?.vendor?.length : 0 }}
                    </h5>
                  </div>
                  <div class="col-sm-6 d-flex justify-content-end">
                    <mat-icon class="fs-6"> message</mat-icon>
                    <h5 class="ml-1 fs-6">
                      {{ x.total_responses ? x.total_responses : 0 }}
                    </h5>
                  </div>
                </div>

                <div
                  class="st-col"
                  [ngStyle]="{
                    background:
                      x.status == 'draft'
                        ? '#d38128'
                        : x.status == 'publish'
                        ? 'blue'
                        : x.status == 'shortlist'
                        ? 'green'
                        : x.status == 'reject'
                        ? 'darkred'
                        : '#737373'
                  }"
                >
                  {{ x.status }}
                </div>
              </div>
              <div class="dos">
                <div class="text-center">
                  <img
                    src="/assets/images/date.png"
                    width="15px"
                    class="mr-1"
                  />
                  <span style="font-size: 10px">{{
                    x.submission_date | date : "d-MM-YYYY"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="statements?.length == 0">
          <ng-container *ngTemplateOutlet="noResponse"></ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      label="Completed ({{
        completedStatements.length ? completedStatements.length : 0
      }})"
    >
      <div style="overflow-x: hidden">
        <!-- <div class="row mt-2">
                    <div class="col-sm-8">
                        <div style="overflow-x: scroll">
                            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                        </div>
                    </div>
                    <div class="col-sm-4 trn-tc-2">
                        <input type="text" placeholder="Search ..." class="form-control">
                    </div>
                </div> -->
        <div class="tab-holder mt-3" *ngIf="completedStatements?.length > 0">
          <div class="flex-container-1">
            <div class="flex-item p-1" *ngFor="let x of completedStatements">
              <div
                class="trn-x"
                [ngStyle]="{ background: x.color }"
                (click)="
                  navigateById('challenge-workbench/problem-statement/', x.id)
                "
              >
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">
                    {{ x.title }}
                  </h6>
                  <div class="d-flex">
                    <span
                      class="tag-icon mr-1"
                      *ngIf="x?.tag_ids?.length > 0"
                      (click)="$event.stopPropagation()"
                      [matMenuTriggerFor]="menu"
                    >
                      <i class="far fa-tag" style="font-size: 10px"></i
                    ></span>
                  </div>
                </div>
                <mat-menu #menu="matMenu" class="my-class">
                  <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                </mat-menu>

                <p class="desc">{{ x.description }}</p>

                <!-- <div class="st-col text-capitalize" [ngStyle]="{'background': x.status == 'draft' ? '#d33838' : 'green'}">
                                    {{x.status}}</div>
                                    <br> -->
                <div *ngIf="x.latest_date" class="lates-update-style">
                  <i>Updated {{ getLatestUpdated(x.latest_date) }}</i>
                </div>
                <div class="row rcp">
                  <div class="col-sm-6 d-flex justify-content-start">
                    <mat-icon class="fs-6"> contact_mail</mat-icon>
                    <h5 class="fs-6">
                      {{ x?.vendor?.length ? x?.vendor?.length : 0 }}
                    </h5>
                  </div>
                  <div class="col-sm-6 d-flex justify-content-end">
                    <mat-icon class="fs-6"> message</mat-icon>
                    <h5 class="ml-1 fs-6">
                      {{ x.total_responses ? x.total_responses : 0 }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="dos">
                <div class="text-center">
                  <img
                    src="/assets/images/date.png"
                    width="15px"
                    class="mr-1"
                  />
                  <span style="font-size: 10px">{{
                    x.submission_date | date : "d-MM-YYYY"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="completedStatements?.length == 0">
          <ng-container *ngTemplateOutlet="noResponse"></ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noResponse>
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-5 mx-auto mt-5">
            <img src="./assets/images/no-record.png" class="mt-3"
                height="100%" width="100%">
        </div> -->
      <div class="col-md-8 mx-auto mt-4">
        <p class="text-center text-muted mt-2 font-weight-bold mb-1">
          {{ staticText?.common.no_data_found }}
        </p>
      </div>
    </div>
  </div>
</ng-template>
