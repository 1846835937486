import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { formatDistanceToNow } from 'date-fns';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
@Component({
    selector: 'app-problem-statement-detail',
    templateUrl: './problem-statement-detail.component.html',
    styleUrls: ['./problem-statement-detail.component.scss']
})
export class ProblemStatementDetailComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    csId:any;
    csDetails:any;
    statementResponse:any = [];
    staticText: any = (textConfiguration as any).default;
  
    constructor(private router: Router,
        private route: ActivatedRoute,
        private spinner : NgxSpinnerService,
        private cw: ChallengeWorkbenchService,
        private toastrService:ToastrService,
        private breadCrumService: BreadcrumbService,
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((res: any) => {
            this.csId = atob(res.get('id'));
            this.getChallengeStatementById();
          });
    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }
    navigateToById(url:any,id:any){
        this.router.navigate([url+btoa(id)])
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    getName(name: any) {
        return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
      }
    getChallengeStatementById(){
        this.spinner.show();
        this.cw.getChallengeStatementById(this.csId).subscribe((res:any)=>{
            this.spinner.hide();
            res.vendor.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            res.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.csDetails = res;
            this.cw.setData(this.csDetails);
            let last = [
                {label:'CoSInE' , url:'/challenge-workbench/challenge-statements'},
                {label:'Challenge Workbench' , url:'/challenge-workbench/challenge-statements'},
                {label: res?.title , url:'' }
              ]
               this.breadCrumService.setBreadcrumb(last);
            this.getResponseDetails();
        })
    }

    downloadFile(file:any){
        this.spinner.show();
        this.cw.downloadAttachmentFile(file.id).subscribe((res: any) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute('download', file.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success('File downloaded successfully');
        this.spinner.hide();
        }, (error: any) => {
        this.spinner.hide();
        throw error;
        })
    }

   async getResponseDetails(){
     await this.cw.getChallengeStatementResponse(this.csId).subscribe((res: any) => {
            this.statementResponse = res;
        });
    }

    ngOnDestroy() {
        this.cw.setData('');
    }
    updateTimings(dateTime:any){
        if(dateTime){
        const timestamp = new Date(dateTime);
        return formatDistanceToNow(timestamp, { addSuffix: true });
        }
        return '';
    }

    editStatementURL(){
        this.router.navigate(['challenge-workbench/create-statement/'+btoa(this.csId)])
    }

}
