<div class="pb-2">
  <mat-stepper
    labelPosition="bottom"
    #stepper
    id="setupStep"
    style="height: 67%"
    (selectionChange)="selectedStepper($event)"
  >
    <mat-step label="Statement Creation" [state]="stepStates[0]">
      <ng-template matStepLabel>
        <p class="mat-design" matTooltip="Statement Creation">
          Challenge Statement
        </p>
      </ng-template>
      <form [formGroup]="challengeStatementForm">
        <div>
          <!-- <div class="row">
                                <div class="col-sm-6 trn-trc">
                                    <h5>Statement Creation</h5>
                                </div>
                            </div> -->
          <div class="row mt-3">
            <div class="col-sm-12 create-statement">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Challenge Statement Title</mat-label>
                <input matInput formControlName="title" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100 px-1">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  rows="4"
                  formControlName="description"
                ></textarea>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 pr-2">
                <mat-label>Portfolio</mat-label>
                <input
                  matInput
                  [matAutocomplete]="auto"
                  type="text"
                  formControlName="portfolio"
                />
                <mat-icon matSuffix class="muted">search</mat-icon>
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFnName"
                >
                  <mat-option
                    *ngFor="let option of filterOptionPortfolio | async"
                    [value]="option"
                    (onSelectionChange)="onSelectionChangePortfolio($event)"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 pl-2">
                <mat-label>Sub Portfolio</mat-label>
                <input
                  matInput
                  [matAutocomplete]="auto1"
                  type="text"
                  formControlName="subPortfolio"
                />
                <mat-icon matSuffix class="muted">search</mat-icon>
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="displayFnName"
                >
                  <mat-option
                    *ngFor="let option of filterOptionSubPortfolio | async"
                    [value]="option"
                    (onSelectionChange)="
                      onSelectionChangeSubportPortfolio($event)
                    "
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 pr-2">
                <mat-label>Program</mat-label>
                <input
                  matInput
                  [matAutocomplete]="auto5"
                  type="text"
                  formControlName="program"
                />
                <mat-icon matSuffix class="muted">search</mat-icon>
                <mat-autocomplete
                  #auto5="matAutocomplete"
                  [displayWith]="displayFnName"
                >
                  <mat-option
                    *ngFor="let option of filterOptionProgram | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 pl-2">
                <mat-label>Owner</mat-label>
                <input matInput type="text" readonly formControlName="owners" />
              </mat-form-field>

              <app-tag
                [noIds]="true"
                [tagsIds]="createStatementTags"
                (childTags)="getChildTags($event)"
              ></app-tag>
              <mat-form-field appearance="outline" class="w-100 px-1 mt-3">
                <mat-label>Impact</mat-label>
                <input matInput formControlName="impact" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 px-1">
                <mat-label>Technology stack /preferences</mat-label>
                <input matInput formControlName="technologies" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-50 px-1">
                <mat-label>Last date of Submission</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  readonly
                  fullWidth
                  status="basic"
                  (click)="picker1.open()"
                  formControlName="submission_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1> </mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100 px-1">
                <mat-label>POC expectations</mat-label>
                <textarea
                  matInput
                  rows="3"
                  formControlName="poc_expectations"
                ></textarea>
              </mat-form-field>
              <h6 class="mt-4">Link</h6>
              <div class="row">
                <div class="col-sm-11">
                  <mat-form-field appearance="outline" class="w-100 px-1">
                    <mat-label>Link URL</mat-label>
                    <input
                      matInput
                      [(ngModel)]="linkURL"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
                <div
                  class="col-sm-1"
                  style="display: flex; justify-content: space-between"
                >
                  <button mat-icon-button color="success" (click)="addLink()">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
              <mat-accordion>
                <mat-expansion-panel
                  [expanded]="true"
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                  style="border-radius: 20px"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Links ({{ challengeStatementForm.value.links.length }})
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-x: scroll">
                    <div
                      class="d-flex flex-row flex-nowrap overflow-auto"
                      style="padding: 10px"
                    >
                      <div
                        class="card card-block mx-2"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: end;
                          border-radius: 15px;
                          cursor: pointer;
                        "
                        *ngFor="
                          let x of challengeStatementForm.value.links;
                          let i = index
                        "
                        (click)="openLink(x)"
                      >
                        <div class="d-flex align-items-center">
                          <mat-icon class="lk">link</mat-icon>
                          <p class="m-0 asw">{{ x }}</p>
                          <mat-icon color="remove" (click)="removeLink(i)"
                            >clear</mat-icon
                          >
                        </div>
                      </div>
                      <div
                        *ngIf="challengeStatementForm.value.links.length == 0"
                      >
                        No links added
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>

              <h6 class="mt-3">Attach File</h6>
              <div class="row">
                <!-- <div class="col-sm-10">
                                            <mat-form-field appearance="outline" class="w-100 px-1">
                                                <mat-label>Attachment Name</mat-label>
                                                <input matInput>
                                            </mat-form-field>
                                        </div> -->
                <div
                  class="col-sm-2"
                  style="display: flex; justify-content: space-between"
                >
                  <!-- <button mat-icon-button color="secondary">
                                                <mat-icon>upload</mat-icon>
                                            </button> -->
                </div>
              </div>
              <mat-accordion formArrayName="files">
                <mat-expansion-panel
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = true"
                  [expanded]="true"
                  style="border-radius: 20px"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div>
                        Attachments ({{ fileControls.controls.length }})
                      </div>
                      <button mat-icon-button color="success">
                        <input
                          hidden
                          #fileInput
                          type="file"
                          id="file"
                          (change)="onFileSelected($event)"
                        />
                        <mat-icon (click)="fileInput.click()">add</mat-icon>
                      </button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="padding: 20px; overflow-x: scroll">
                    <div
                      class="d-flex flex-row flex-nowrap overflow-auto"
                      style="padding: 10px 0"
                    >
                      <div
                        class="card card-block mx-2"
                        style="
                          width: 150px;
                          height: 150px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 15px;
                        "
                        *ngFor="
                          let x of problemStatementDetails?.problem_statement_files;
                          let i = index
                        "
                      >
                        <div class="mr-2 mt-2">
                          <img
                            *ngIf="
                              x?.file_type == 'doc' || x?.file_type == 'docx'
                            "
                            src="./assets/images/word.svg"
                            height="40"
                          />
                          <img
                            *ngIf="x?.file_type == 'pdf'"
                            src="./assets/images/pdf.svg"
                            height="40"
                          />
                          <img
                            *ngIf="
                              x?.file_type == 'xls' || x?.file_type == 'xlsx'
                            "
                            src="./assets/images/excel.svg"
                            height="40"
                          />
                          <img
                            *ngIf="x?.file_type == 'csv'"
                            src="./assets/images/csv.png"
                            height="40"
                          />
                          <mat-icon
                            *ngIf="
                              x?.file_type !== 'pdf' &&
                              x?.file_type !== 'doc' &&
                              x?.file_type !== 'docx' &&
                              x?.file_type !== 'xls' &&
                              x?.file_type !== 'xlsx' &&
                              x?.file_type !== 'csv'
                            "
                            mat-list-icon
                            class="material-icons my-auto fs-1 text-danger"
                          >
                            description</mat-icon
                          >
                        </div>
                        <p class="m-0">{{ x?.file_name }}</p>
                        <mat-icon color="remove" (click)="DeleteFile(x?.id)"
                          >clear</mat-icon
                        >
                      </div>
                      <div
                        class="card card-block mx-2"
                        style="
                          width: 150px;
                          height: 150px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 15px;
                        "
                        *ngFor="let x of fileControls.controls; let i = index"
                      >
                        <div class="mr-2 mt-2">
                          <img
                            *ngIf="
                              getType(x?.value?.name) == 'doc' ||
                              getType(x?.value?.name) == 'docx'
                            "
                            src="./assets/images/word.svg"
                            height="40"
                          />
                          <img
                            *ngIf="getType(x?.value?.name) == 'pdf'"
                            src="./assets/images/pdf.svg"
                            height="40"
                          />
                          <img
                            *ngIf="
                              getType(x?.value?.name) == 'xls' ||
                              getType(x?.value?.name) == 'xlsx'
                            "
                            src="./assets/images/excel.svg"
                            height="40"
                          />
                          <img
                            *ngIf="getType(x?.value?.name) == 'csv'"
                            src="./assets/images/csv.png"
                            height="40"
                          />
                          <mat-icon
                            *ngIf="
                              getType(x?.value?.name) !== 'pdf' &&
                              getType(x?.value?.name) !== 'doc' &&
                              getType(x?.value?.name) !== 'docx' &&
                              getType(x?.value?.name) !== 'xls' &&
                              getType(x?.value?.name) !== 'xlsx' &&
                              getType(x?.value?.name) !== 'csv'
                            "
                            mat-list-icon
                            class="material-icons my-auto fs-1 text-danger"
                          >
                            description</mat-icon
                          >
                        </div>
                        <p class="m-0">{{ x?.value?.name }}</p>
                        <mat-icon color="remove" (click)="removeFile(i)"
                          >clear</mat-icon
                        >
                      </div>
                      <div
                        *ngIf="
                          fileControls.controls.length == 0 &&
                          problemStatementDetails?.problem_statement_files
                            .length == 0
                        "
                      >
                        No file attached
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <h6 class="mt-4">OKR Map</h6>
              <div style="overflow-x: scroll">
                <div
                  class="d-flex flex-row flex-nowrap overflow-auto"
                  style="padding: 10px 0"
                >
                  <div
                    class="card card-block mx-2"
                    style="
                      position: relative;
                      color: white;
                      min-width: 150px;
                      height: 150px;
                      border-radius: 15px;
                      padding: 12px;
                      font-size: 13px;
                    "
                    *ngFor="let x of okr; let i = index"
                    [ngStyle]="{ background: x.color }"
                  >
                    <p class="m-0 okr-nm">{{ x.title }}</p>

                    <mat-checkbox
                      class="example-margin"
                      [checked]="x.selected"
                      (change)="addOKR($event, x)"
                      style="position: absolute; bottom: 5px; right: 5px"
                    ></mat-checkbox>
                  </div>
                </div>
              </div>

              <mat-accordion formArrayName="okrs">
                <mat-expansion-panel
                  (opened)="panelOpenState1 = true"
                  [expanded]="okrControls.controls.length > 0"
                  (closed)="panelOpenState1 = false"
                  style="border-radius: 20px"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title> Selected OKR’s </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="padding: 20px">
                    <div
                      class="row mb-2"
                      *ngFor="let kr of okrControls.controls; let i = index"
                      [formGroupName]="i"
                    >
                      <div class="col-sm-1">{{ i + 1 }}.</div>
                      <div class="col-sm-5">
                        {{ kr?.value.title }}
                      </div>
                      <div class="col-sm-5">
                        <input
                          type="text"
                          [(ngModel)]="kr?.value.comment"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                        />
                      </div>
                      <div
                        class="col-sm-1"
                        style="display: flex; align-items: center"
                      >
                        <mat-icon color="warn">delete</mat-icon>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div class="trn-trc-1 mt-4">
            <button
              mat-button
              mat-flat-primary
              color="primary"
              class="bg-primary text-white"
              aria-label="add"
              (click)="next()"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step label="Vendor Selection">
      <ng-template matStepLabel>
        <p class="mat-design" matTooltip="Vendor Selection">Vendor</p>
      </ng-template>
      <div>
        <!-- <div class="row">
                            <div class="col-sm-12 trn-trc">
                                <h5>Prospect Provider Selection</h5>
                            </div>
                            <div class="col-sm-6 trn-trc-1">
                                <button mat-mini-fab color="primary" aria-label="add" (click)="navigateTo('challenge-workbench/create-prospect-provider')">
                                    <mat-icon>add</mat-icon>
                                </button> 
                            </div>
                        </div> -->

        <!-- <div class="row mt-4">
                            <div class="col-sm-8">
                                <div style="overflow-x: scroll">
                                    <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                                </div>
                            </div>
                            <div class="col-sm-4 trn-tc-2">
                                <input type="text" placeholder="Search ..." class="form-control">
                            </div>
                        </div> -->
        <div class="trn-trc">
          <span
            *ngFor="let i of alpha"
            style="text-transform: uppercase"
            class="letter"
            [ngClass]="{ letterActive: i === searchbyLetter }"
            (click)="searchByLetter(i)"
            >{{ i }}</span
          >
        </div>

        <div class="tab-holder">
          <div class="flex-container-1">
            <div class="flex-item p-1" *ngFor="let x of serviceProviderList">
              <div class="trn-x" [ngStyle]="{ background: x.color }">
                <div style="display: flex; align-items: center; height: 50px">
                  <img [src]="x.url" class="is-img" *ngIf="x.url" />
                  <img
                    src="assets/images/No_image_available.webp"
                    class="is-img"
                    *ngIf="!x.url"
                  />
                  <h6 class="mb-0 trn-nm">{{ x.name }}</h6>
                </div>
                <p class="mb-1" style="font-size: 9px; margin-top: 10px">
                  Sector
                  <span
                    style="
                      display: block;
                      width: 100%;
                      font-weight: 700;
                      font-size: 12px;
                    "
                  >
                    <span *ngIf="x.sector">{{ x.sector }} </span>
                    <span *ngIf="!x.sector">Not Available</span>
                  </span>
                </p>
                <p class="mb-1" style="font-size: 12px; margin-top: 10px">
                  <span *ngIf="x.is_gdp"> Empanelled</span>
                  <span *ngIf="!x.is_gdp"> Non Empanelled</span>
                </p>
                <div
                  class="row"
                  style="position: absolute; bottom: 8px; font-size: 12px"
                >
                  <div
                    class="col-sm-8 d-flex justify-content-start"
                    style="font-size: 8px"
                  >
                    <a *ngIf="x.link" href="{{ x.link }}" target="_blank"
                      ><mat-icon style="color: #fff; font-size: 20px"
                        >link</mat-icon
                      ></a
                    >
                    <a href="mailto:{{ x?.email }}"
                      ><mat-icon style="color: #fff; font-size: 20px"
                        >mail</mat-icon
                      ></a
                    >
                    <a href="tel:{{ x?.contact_no }}">
                      <mat-icon style="color: #fff; font-size: 20px">
                        phone</mat-icon
                      ></a
                    >
                  </div>
                  <div class="col-sm-4 d-flex justify-content-end float-right">
                    <mat-icon *ngIf="x.is_recomended">thumb_up_alt</mat-icon>
                  </div>
                </div>
              </div>
              <mat-checkbox
                class="action-vert"
                [checked]="x?.selected"
                (change)="selectServiceProvider($event, x.id)"
              ></mat-checkbox>

              <!-- <button mat-icon-button color="primary" [mat-menu-trigger-for]="actionmenu" 
                                        (click)="$event.stopPropagation()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #actionmenu="matMenu">
                                        <button mat-menu-item (click)="deleteVendor(x.id)">
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu> -->
            </div>

            <!-- <div class="flex-item p-1" *ngFor="let provider of serviceProviderList;">
                                    <div class="trn-x" [ngStyle]="{'background': provider.color}">
                                        <div class="d-flex">
                                            <div>
                                                <img *ngIf="provider.url" src="{{provider.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                                <span class="name-1" *ngIf="!provider.url"   [ngStyle]="{'backgroundColor': provider.logoColor}">{{getName(provider.name)}}</span>
                                            </div>
                                            
                                            <h6 class="mb-0 mt-2 ml-2 trn-nm" style="width: 80%" matTooltip="{{provider.name}}"> {{provider.name}}
                                            </h6>
                                            <mat-checkbox  [checked]="provider?.selected" (change)="selectServiceProvider($event,provider.id)"></mat-checkbox>
                                        </div>
                                        <div class="d-flex justify-content-start mt-2" matTooltip="Sector" style="font-size:12px">
                                            <mat-icon> business</mat-icon> 
                                            <span class="ml-1 mt-1 fw-500">{{provider.sector}}</span>
                                        </div>
                                        <div class="st-col text-capitalize" >
                                            <span style="background-color: green; font-size: 12px;">
                                                {{ provider?.is_gdg == true ? 'Empanelled' :''}}
                                            </span>
                                             </div>
                                        <div class="row" style="position:absolute;bottom:8px;font-size:12px">
                                            <div class="col-sm-8 d-flex justify-content-start" style="font-size: 8px;">
                                                <a *ngIf="provider.link" href="{{provider.link}}" target="_blank"><mat-icon style="color:#fff;font-size:20px">link</mat-icon></a>
                                                <a href="mailto:{{provider?.email}}" ><mat-icon style="color:#fff;font-size:20px">mail</mat-icon></a>
                                                <a href="tel:{{provider?.contact_no}}">	<mat-icon style="color:#fff;font-size:20px"> phone</mat-icon></a>
                                            </div>
                                            <div class="col-sm-4 d-flex justify-content-end float-right">
                                                <mat-icon *ngIf="provider.is_recomended">thumb_up_alt</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
          </div>
        </div>

        <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 mt-3 ptable">
                        
                                <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef width ="10%">
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)"
                                                [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                                </ng-container>
                                <ng-container matColumnDef="org">
                                <th mat-header-cell *matHeaderCellDef> Org Name / Startup Name </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <span style="display: flex; align-items: center;">
                                        <img src="assets/images/defimg.png" class="mr-2">
                                        {{element.name}}
                                    </span>
                                </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="accelerator">
                                <th mat-header-cell *matHeaderCellDef> Accelerator </th>
                                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="industry">
                                <th mat-header-cell *matHeaderCellDef> Location </th>
                                <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                                </ng-container>
                                <ng-container matColumnDef="sector">
                                <th mat-header-cell *matHeaderCellDef> Sector </th>
                                <td mat-cell *matCellDef="let element"> {{element.sector}} </td>
                                </ng-container>
                                <ng-container matColumnDef="category">
                                    <th mat-header-cell *matHeaderCellDef> Founder Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.founder_name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="tags">
                                    <th mat-header-cell *matHeaderCellDef> Tags </th>
                                    <td mat-cell *matCellDef="let element"> 
                                    </td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="selection.toggle(row)">
                                </tr>
                            </table> -->

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>   -->
        <div class="trn-trc-1 mt-4" style="justify-content: space-between">
          <div>
            <button
              mat-stroked-button
              mat-button
              color="primary"
              aria-label="add"
              (click)="previous()"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              mat-stroked-button
              mat-button
              color="primary"
              (click)="submitData('draft')"
              aria-label="add"
              class="mx-1"
            >
              Draft
            </button>
            <button
              mat-button
              mat-flat-button
              color="primary"
              class="bg-primary text-white"
              (click)="submitData('published')"
              aria-label="add"
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
