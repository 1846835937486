import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinner } from "ngx-spinner";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { NgxSpinnerService } from "ngx-spinner";
import { formatDistanceToNow } from "date-fns";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/shared/services/filter.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-problem-statements",
  templateUrl: "./problem-statements.component.html",
  styleUrls: ["./problem-statements.component.scss"],
})
export class ProblemStatementsComponent implements OnInit {
  statements: any;
  completedStatements: any;
  filterObj: any;
  portfolioId: any;
  subportfolioId: any;
  programId: any;
  tagFilterId: any = [];
  staticText: any = (textConfiguration as any).default;
  user: any;
  dataLoaded: boolean = false;

  private subscription: Subscription;
  constructor(
    private router: Router,
    private cw: ChallengeWorkbenchService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    if (this.user?.is_vendor) {
      this.router.navigateByUrl(
        "prospect-provider-workbench/challenge-statement"
      );
    }

    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        let awl = false;
        this.filterObj = res;
        Object.keys(this.filterObj).forEach((element: any) => {
          if (this.filterObj[element]?.array?.length > 0) {
            awl = true;
            return;
          }
        });
        if (Object.keys(this.filterObj).length > 0) {
          awl ? this.getFilteredList() : this.getChallengeStatemets();
        } else {
          this.getChallengeStatemets();
        }
      }
    );
  }

  navigateTo(url: any) {
    this.router.navigateByUrl(url);
  }

  navigateById(url: any, id: any) {
    // if(!this.user?.is_vendor){
    this.router.navigate([url + btoa(id)]);
    // }
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getChallengeStatemets() {
    this.statements = [];
    this.completedStatements = [];
    this.spinner.show();
    this.dataLoaded = false;
    this.cw.getChallengeStatement().subscribe(
      (res: any) => {
        this.spinner.hide();
        res.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
          if (element.status == "draft") {
            this.statements.push(element);
          }
          if (element.status == "published") {
            this.completedStatements.push(element);
          }
        });
        this.dataLoaded = true;
      },
      (error) => {
        this.spinner.hide();
        this.dataLoaded = true;
      }
    );
  }

  async getFilteredList() {
    let queryParam: any = "";
    if (this.filterObj?.portfolio?.array?.length > 0) {
      this.filterObj.portfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&portfolio_id=${element.id}`;
      });
    } else if (this.filterObj?.subportfolio?.array?.length > 0) {
      this.filterObj.subportfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&sub_portfolio_id=${element.id}`;
      });
    } else if (this.filterObj?.program?.array?.length > 0) {
      this.filterObj.program.array.forEach((element: any) => {
        queryParam = queryParam + `&program_id=${element.id}`;
      });
    } else if (this.filterObj?.tag?.array?.length > 0) {
      let tags: any = [];
      this.filterObj.tag.array.forEach((element: any) => {
        tags.push(element.id);
      });
      queryParam = `&tag_ids=${tags}`;
    }

    this.spinner.show();
    this.statements = [];
    this.completedStatements = [];

    this.cw.getFilteredChallengeStatement(queryParam).subscribe(
      (res: any) => {
        this.spinner.hide();
        res.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
          if (element.status == "draft") {
            this.statements.push(element);
          }
          if (element.status == "published") {
            this.completedStatements.push(element);
          }
        });
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getLatestUpdated(dateTime: any) {
    if (dateTime) {
      const timestamp = new Date(dateTime);
      return formatDistanceToNow(timestamp, { addSuffix: true });
    }
    return "";
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteChallenge(id: any, i: any) {
    this.cw.deleteChallenge(id).subscribe((res: any) => {
      this.statements.splice(i, 1);
      this.toastrService.success("Challenge deleted successfully");
    });
  }
}
