import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {
  currentYear:any;
  staticText: any = (textConfiguration as any).default;
  constructor(private router: Router) { }
  

  ngOnInit(): void {
    
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

}
