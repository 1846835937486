import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxSpinnerService } from 'ngx-spinner';

  
 
@Component({
    selector: 'app-prospect-providers',
    templateUrl: './prospect-providers.component.html',
    styleUrls: ['./prospect-providers.component.scss']
})
export class ProspectProvidersComponent implements OnInit {
    alpha: any;
    displayedColumns: string[] = ['select', 'org', 'accelerator', 'industry', 'sector' ,'category', 'tags'];
    serviceProviderList:any = []
    dataSource = new MatTableDataSource<any>();
    selection = new SelectionModel<any>(true, []);
    staticText: any = (textConfiguration as any).default;
    searchbyLetter:any = '';
  
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    constructor(private router: Router,
      private cw: ChallengeWorkbenchService,
      private spinner : NgxSpinnerService,
    ) { 
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }

      toggleAllRows() {
        if (this.isAllSelected()) {
          this.selection.clear();
          return;
        }
    
        this.selection.select(...this.dataSource.data);
      }
    
      /** The label for the checkbox on the passed row */
      checkboxLabel(row?: any): string {
        if (!row) {
          return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
      }


    ngOnInit(): void {
        this.alpha = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z","A-Z"];
        this.getAllServiceProvider();
      }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
      return 'hsla(' + Math.floor(Math.random()*360) + ', 50%, 25%, 90%)';
    }  
    getName(name: any) {
      return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
    }
    getAllServiceProvider(){
      this.serviceProviderList = [];
      this.spinner.show();
      this.cw.getAllServiceProviderList(this.searchbyLetter).subscribe((res:any)=>{
       res.forEach((element:any) => {
        element.color = this.getGMapRandomColor();
        element.logoColor = this.getGMapRandomColor();
        this.serviceProviderList.push(element);
       });
       this.spinner.hide();
    })
    }

    navigateById(id:any){
      this.router.navigate(['challenge-workbench/create-prospect-provider/'+btoa(id)]);
    }

    searchByLetter(letter:any){
      this.searchbyLetter = letter;
      if(letter == "A-Z"){
        this.searchbyLetter = "";
      }
      this.getAllServiceProvider();
    }
    
 
}