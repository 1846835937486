import { Component, OnInit } from '@angular/core';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  currentYear:any;
  csDetails:any;
  staticText: any = (textConfiguration as any).default;
  loading:any = false;
  constructor(
    private cw : ChallengeWorkbenchService,
    public router: Router,
    private messageService: MessageService
  ) { }
  

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();
     this.messageService.setGnews('Challenge Statements display - Active')
    this.cw.data$.subscribe((newData) => {
      this.csDetails = newData;
    });
  }

}
